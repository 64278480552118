import Rails, { $ } from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../bootstrap.bundle.min.js";
require("jquery");
require("@nathanvda/cocoon");
require("signature_pad");
import "trix";
import "@rails/actiontext";
Rails.start();
Turbolinks.start();
ActiveStorage.start();
require("trix");
require("@rails/actiontext");
import "controllers";

const excludedPaths = ["/users/sign_in", "/users/sign_up"];
if (!excludedPaths.includes(window.location.pathname)) {
  let timeout;
  let logoutCountdown;
  let countdown = 180; // Countdown starts at 3 minutes

  const timeoutDuration = 5 * 60 * 1000; // 5 minutes timeout
  const countdownDuration = 3 * 60 * 1000; // 3 minutes countdown

  function resetTimer() {
    if (logoutCountdown) return; // If countdown is running, don't reset
    clearTimeout(timeout);
    timeout = setTimeout(showSessionModal, timeoutDuration);
  }

  function showSessionModal() {
    document.cookie = `last_visited_page=${window.location.href}; path=/`;

    const modalHTML = `
      <div id="custom-modal" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 30px; width: 400px; background-color: white; border-radius: 12px; box-shadow: 0 4px 8px rgba(0,0,0,0.2); z-index: 1001;">
        <h3 style="text-align: center; font-size: 18px; margin-bottom: 20px;">Your session will expire soon.</h3>
        <p style="text-align: center; font-size: 16px; margin-bottom: 10px;">You will be logged out in <span id="countdown-timer">${countdown}</span> seconds.</p>
        <p style="text-align: center; font-size: 16px; margin-bottom: 30px;">Do you want to stay logged in?</p>
        <div style="display: flex; justify-content: center; gap: 20px;">
          <button id="stay-logged-in" style="padding: 10px 20px; background-color: #4CAF50; color: white; border: none; border-radius: 25px; font-size: 16px; cursor: pointer;">Stay Logged In</button>
          <button id="logout" style="padding: 10px 20px; background-color: #f44336; color: white; border: none; border-radius: 25px; font-size: 16px; cursor: pointer;">Log Out</button>
        </div>
      </div>
      <div id="modal-overlay" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); z-index: 1000;"></div>
    `;

    document.body.insertAdjacentHTML("beforeend", modalHTML);
    document.body.style.pointerEvents = "none";

    const stayLoggedInButton = document.getElementById("stay-logged-in");
    const logoutButton = document.getElementById("logout");
    const overlay = document.getElementById("modal-overlay");
    const modal = document.getElementById("custom-modal");
    const countdownElement = document.getElementById("countdown-timer");

    modal.style.pointerEvents = "auto";

    // Countdown logic - It keeps running even if user moves the mouse or types
    logoutCountdown = setInterval(() => {
      countdown -= 1;
      countdownElement.textContent = countdown;
      if (countdown <= 0) {
        clearInterval(logoutCountdown);
        performLogout();
      }
    }, 1000);

    stayLoggedInButton.addEventListener("click", () => {
      clearInterval(logoutCountdown); // Stop countdown
      logoutCountdown = null; // Reset logout state
      countdown = 180; // Reset countdown to 3 minutes
      document.body.style.pointerEvents = "auto";
      document.getElementById("custom-modal").remove();
      overlay.remove();
      resetTimer(); // Restart the session timer
    });

    logoutButton.addEventListener("click", performLogout);
    overlay.addEventListener("click", () => {
      document.body.style.pointerEvents = "auto";
      document.getElementById("custom-modal").remove();
      overlay.remove();
    });
  }

  function performLogout() {
    document.body.style.pointerEvents = "auto";
    document.getElementById("custom-modal")?.remove();
    document.getElementById("modal-overlay")?.remove();

    fetch("/users/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
      },
    })
    .then((response) => {
      if (response.ok) {
        window.location.href = "/users/sign_in";
      }
    })
    .catch((error) => {
      console.error("Error logging out:", error);
    });
  }

  document.addEventListener("mousemove", resetTimer);
  document.addEventListener("keypress", resetTimer);
  resetTimer();
  
}
